import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import useCustomerInfo from "../../../../../hooks/useCustomerInfo";
import PopSelectAddress from "./PopSelectAddress";
import useSelectedLanguge from "../../../../../hooks/useSelectedLanguge";
import SimpleButton from "../../../../../shared/components/SimpleButton";
import DatePicker from "react-date-picker";
import { getCustomerInfoFromStroge, getShopDataFromStrg } from "../../../utils";
import SideLogoName from "../../../../../shared/components/SideLogoName";
import BackArrow from "../../../../../assets/img/svgs/BackArrow";
import moment from "moment";
const CreateNewCustomer: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { bussinessName } = useParams();
  const { getCustomerInfo, loading } = useCustomerInfo();
  const [userName, setUserName] = useState<string>("");
  const [isCalenderOpen, setIsCalenderOpen] = useState<boolean>(false);
  const [showAddressModal, setShowAddressModal] = useState<boolean>(false);
  const { selectedLanguageCode } = useSelectedLanguge();
  const shopDataLocal = getShopDataFromStrg("/" + bussinessName!);
  const selectedLatLng =
    shopDataLocal !== null && shopDataLocal?.outletInfo?.selectedLatLon;
  const isBirthdayDiscount =
    shopDataLocal !== null &&
    shopDataLocal?.shopInfo?.resto_meta?.BIRTHDAY_DISCOUNT === "ENABLED"
      ? true
      : false;
  const otpDataStrg = localStorage.getItem("otpDataTemp");
  const parsedData = otpDataStrg !== null && JSON.parse(otpDataStrg);
  const [showError, setShowError] = useState<boolean>(false);
  const [dateOfBirth, setDateOfBirth] = useState<string>("");
  const defaultValue = new Date("1970-01-01");
  const valuesFromStorage = getShopDataFromStrg("/" + bussinessName!);
  const shopInfo = valuesFromStorage !== null && valuesFromStorage?.shopInfo;
  const closeSelectDatePop = () => {
    navigate(`/${bussinessName}/basket`);
  };
  const createNewCustomerHandler = () => {
    if (userName.length !== 0) {
      var dob = moment(dateOfBirth).format("DD/MM/YYYY");
      getCustomerInfo(parsedData.data.msisdn, userName, dob).then((value) => {
        if (!value.exsitingUser) {
          navigatoToNextPage(value.cutomerData);
        }
      });
    } else {
      setShowError(true);
    }
  };
  const onChangeDateHandler = (value: any) => {
    // const { id, value } = e.target;
    // console.log(moment(value).format("DD/MM/YYYY"));

    setDateOfBirth(value);
  };
  const addressModalHide = (action: string) => {
    setShowAddressModal(false);
    // close
    if (action === "navigate") {
      navigate(`/${bussinessName}/place-order`, { replace: true });
      return;
    }
    if (action === "close") {
      navigate(`/${bussinessName}/basket`, { replace: true });
      return;
    }
  };
  const addressShowHandler = () => {
    setShowAddressModal(true);
  };
  const navigatoToNextPage = async (value: any) => {
    var customerInfoTemp = {};
    var customerInfo = getCustomerInfoFromStroge();
    if (customerInfo !== null) {
      customerInfoTemp = {
        ...customerInfo,
        name: userName,
      };
    }
    localStorage.removeItem("otpDataTemp");
    localStorage.setItem("customerInfo", JSON.stringify(customerInfoTemp));
    addressShowHandler();
  };
  // useEffect(() => {
  //   var getCustomerInfo = getCustomerInfoFromStroge();
  //   if (getCustomerInfo !== null) {
  //     addressModalHide("navigate");
  //   }
  // }, []);
  return (
    <div lang={shopInfo.default_lang}>
      <>
        <div id="pop_cart_page4" className="pop_receiver pop_up_maps_main ">
          <div className="popup_cart_full popup_from_bottom  popup_slide landscape">
            <div className="content" id="">
              <div className="cart_head_container">
                <div className="container">
                  <div className="header_cart_page">
                    <div className="cart_heading">&nbsp;</div>

                    <span
                      onClick={closeSelectDatePop}
                      className="target_slide glyphicon"
                    >
                      <BackArrow />
                    </span>
                  </div>
                </div>
              </div>
              {/* <!-- content start here --> */}
              <div className="cart_content">
                <div className="cart_content_to_marg">
                  <div className="cart_content_row">
                    <h3 className="">{t("create_new_acount")}</h3>
                    <p className="light_grey_text res_font_size1">
                      {t("create_new_acount_txt")}
                    </p>
                  </div>
                  <form className=" needs-validation" noValidate>
                    <div className="cart_content_row">
                      <div className="title_acc_cell">
                        {t("text_field_place_holder") + "*"}
                      </div>
                      <div>
                        <input
                          onChange={(e) => {
                            setUserName(e.target.value);
                            setShowError(false);
                          }}
                          type="text"
                          className={`cart_mobile_input_type form-control ${
                            showError ? "border-error" : ""
                          }`}
                          required
                          name="fname"
                        />
                        {showError && (
                          <div
                            className="invalid-feedback "
                            style={{ color: "red" }}
                          >
                            Please enter your full name
                          </div>
                        )}
                      </div>

                      {isBirthdayDiscount && (
                        <div className="dob-picker-wrapper">
                          <div className="title_acc_cell">{t("enter_dob")}</div>
                          <DatePicker
                            minDate={new Date("1924-01-01")}
                            maxDate={new Date("2010-01-01")}
                            onCalendarClose={() => {
                              setIsCalenderOpen(false);
                            }}
                            onCalendarOpen={() => {
                              setIsCalenderOpen(true);
                            }}
                            // className="form-control dob-input-filed "
                            format="dd/MM/yyyy"
                            clearIcon={false}
                            onChange={(value) => onChangeDateHandler(value)}
                            value={
                              dateOfBirth == "" ? defaultValue : dateOfBirth
                            }
                          />
                        </div>
                      )}
                    </div>
                    <br />
                  </form>
                </div>

                {!isCalenderOpen && (
                  <SimpleButton
                    titleProp={t("create_button")}
                    loading={loading}
                    t={t}
                    onPressHandler={createNewCustomerHandler}
                  />
                )}
              </div>
              {/* <!-- content end here --> */}

              {/* <div className="cart_foot">
            <button
              className="cart_foot_button"
              onClick={createNewCustomerHandler}
            >
              <div className="">{t("create_button")}</div>
            </button>
          </div> */}
            </div>
          </div>

          {showAddressModal && (
            <PopSelectAddress
              onPressClose={() => addressModalHide("close")}
              selectedLatLng={selectedLatLng}
              onSaveAddressClose={() => addressModalHide("navigate")}
            />
          )}
        </div>

        {shopInfo && (
          <SideLogoName
            logoUrl={shopInfo?.site_logo}
            bussinessName={shopInfo?.name[selectedLanguageCode]}
          />
        )}
      </>
    </div>
  );
};

export default CreateNewCustomer;
