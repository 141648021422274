import React from "react";
import SideLogoName from "./SideLogoName";

const PageNotFound: React.FC = () => {
  const logo = require("../../assets/img/main_logo.png");
  const openUrl = () => {
    window.location.replace("https://dukany.io/");
  };
  return (
    <>
      <div id="homePage" lang="en" className="homePage">
        <div
          className="page-not-found-wrapper landscape"
          // style={{ float: "left" }}
        >
          {/* <div>{window.location.href}</div> */}
          <h2 className="txt-page-not-found">Page not found</h2>

          <h5 className="txt-page-not-detail">
            We couldn't find the page you're trying to reach.
            {/* But don't
      worry, simply return to the home page. */}
          </h5>
          <div className="return-to-homepage" onClick={openUrl}>
            <div className="error-return-home">Return to home page </div>
          </div>
        </div>
        <SideLogoName logoUrl={logo} bussinessName={"dukany"} />
      </div>
    </>
  );
};

export default PageNotFound;
